export const environment = {
  production: false,
  app: {
    name: 'Firewall Survey Pro',
    env: 'test',
    url: 'https://test2-mobile.hickwood.uk/'
  },
  db: {
    name: 'fire_survey_pro_v2',
  },
  api: {
    url: 'https://test2.api.hickwood.uk/api/v1',
  },
  serverUrl: 'https://test2.api.hickwood.uk',
  storagePrefix: 'fsp_app_test',
  sentryDsn: 'https://6da61f1eabda4d5a90225971baeef8ff@o1040403.ingest.sentry.io/6061405',
  serviceWorkerEnabled: false,
  webEnum: '01hrzez5d7ndwv8r45v31c456j',
};
