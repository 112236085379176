import { Component, OnInit } from '@angular/core';
import { capSQLiteChanges, capSQLiteResult } from '@capacitor-community/sqlite';
import { Platform } from '@ionic/angular';
import { CookieService } from "ngx-cookie-service";
import { databaseSchema } from 'src/assets/schemas/database-schema';
import { SqliteService } from './shared/services/sqlite.service';
import { environment } from 'src/environments/environment';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { state } from '@angular/animations';
import { UiService } from './shared/services/ui.service';
import { BranchDeepLinks, BranchInitEvent } from 'capacitor-branch-deep-links';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  private cookieValue: string;

  public constructor(
    public sqlite: SqliteService,
    private platform: Platform,
    private cookieService: CookieService,
    private uiService: UiService,
    private router: Router
  ) {
    this.initializeApp();
  }

  public async ngOnInit(): Promise<void> {
    this.cookieService.set('SameSite', 'None');
    this.cookieValue = this.cookieService.get('SameSite');

    const darkModeOn =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;

    if (Capacitor.isNativePlatform()) {
      await StatusBar.setStyle({ style: darkModeOn ? Style.Dark : Style.Light });
    }
  }

  private initializeApp() {
    this.platform.ready().then(async () => {
      BranchDeepLinks.addListener('init', (event: BranchInitEvent) => {
        // Retrieve deeplink keys from 'referringParams' and evaluate the values to determine where to route the user
        // Check '+clicked_branch_link' before deciding whether to use your Branch routing logic

        if (event.referringParams['path'] == 'create-password' && event.referringParams['+clicked_branch_link']) {
          this.router.navigate(['/create-password'], { queryParams: { url: event.referringParams['url'] } });
        }

        if (event.referringParams['path'] == 'reset-password' && event.referringParams['+clicked_branch_link']) {
          this.router.navigate(['/reset-password', event.referringParams['token']], { queryParams: { email: event.referringParams['email'] } });
        }
      });

      BranchDeepLinks.addListener('initError', (error: any) => {
        console.error(error);
      });

      this.sqlite.initializePlugin().then(async () => {

        if (this.sqlite.platform === 'web') {
          await customElements.whenDefined('jeep-sqlite');
          const jeepSqliteEl = document.querySelector('jeep-sqlite');
          if (jeepSqliteEl != null) {
            await this.sqlite.initWebStore();
          }
        }

        try {
          let isJsonValid: capSQLiteResult = await this.sqlite.isJsonValid(JSON.stringify(databaseSchema));

          if (!isJsonValid.result) {
            return Promise.reject(new Error('Invalid JSON Schema.'));
          }

          const version1Statements: string[] = [
            "CREATE TABLE sites (id INTEGER PRIMARY KEY NOT NULL,name VARCHAR(125) NOT NULL,customer VARCHAR(125) NOT NULL,status VARCHAR(125) NOT NULL,price_modifier INTEGER UNSIGNED NULL,address_1 VARCHAR(125) NOT NULL,address_2 VARCHAR(125) NULL,address_3 VARCHAR(125) NULL,postcode VARCHAR(125) NOT NULL,author VARCHAR(125) NOT NULL,created_at TIMESTAMP NULL,updated_at TIMESTAMP NULL,deleted_at TIMESTAMP NULL,synced_at TIMESTAMP NULL);",
            "CREATE TABLE site_manufacturers (site_id INTEGER NOT NULL,manufacturer_id INTEGER NOT NULL,PRIMARY KEY (site_id, manufacturer_id));",
            "CREATE TABLE buildings (id INTEGER PRIMARY KEY NOT NULL,name VARCHAR(125) NOT NULL,location VARCHAR(125) NULL,site_id BIGINT UNSIGNED NOT NULL,author VARCHAR(125) NOT NULL,created_at TIMESTAMP NULL,updated_at TIMESTAMP NULL,deleted_at TIMESTAMP NULL,synced_at TIMESTAMP NULL);",
            "CREATE TABLE floors (id INTEGER PRIMARY KEY NOT NULL,name VARCHAR(125) NOT NULL,building_id BIGINT UNSIGNED NOT NULL,author VARCHAR(125) NOT NULL,created_at TIMESTAMP NULL,updated_at TIMESTAMP NULL,deleted_at TIMESTAMP NULL,synced_at TIMESTAMP NULL);",
            "CREATE TABLE drawings (id INTEGER PRIMARY KEY NOT NULL,name VARCHAR(125) NOT NULL,floor_id BIGINT NOT NULL,plan_path LONGTEXT NULL,plan_width VARCHAR(125) NULL,plan_height VARCHAR(125) NULL,survey_completed_at TIMESTAMP NULL,author VARCHAR(125) NOT NULL,created_at TIMESTAMP NULL,updated_at TIMESTAMP NULL,deleted_at TIMESTAMP NULL,synced_at TIMESTAMP NULL);",
            "CREATE TABLE pins (id CHAR(36) PRIMARY KEY NOT NULL,server_id BIGINT NULL,name INTEGER NOT NULL,pin_identifier VARCHAR(125) NOT NULL,drawing_id BIGINT UNSIGNED NOT NULL,latitude DECIMAL(11, 7) NOT NULL,longitude DECIMAL(11, 7) NOT NULL,author VARCHAR(125) NOT NULL,author_id INTEGER NULL,is_local TINYINT(1) NOT NULL,created_at TIMESTAMP NULL,updated_at TIMESTAMP NULL,deleted_at TIMESTAMP NULL,synced_at TIMESTAMP NULL);",
            "CREATE TABLE pin_histories (id CHAR(36) PRIMARY KEY NOT NULL,server_id BIGINT NULL,pin_id CHAR(36) NOT NULL,status_id INTEGER NOT NULL,rating_type VARCHAR(125) NOT NULL,flame_resistance VARCHAR(125) NULL,arc_rating VARCHAR(125) NULL,notes TEXT NULL,author VARCHAR(125) NOT NULL,author_id INTEGER NULL,is_local TINYINT(1) NOT NULL,created_at TIMESTAMP NULL,updated_at TIMESTAMP NULL,deleted_at TIMESTAMP NULL,synced_at TIMESTAMP NULL);",
            "CREATE TABLE pin_history_item_types (pin_history_id CHAR(36) NOT NULL,item_type_id BIGINT UNSIGNED NOT NULL,quantity INTEGER NOT NULL,title VARCHAR(125) NOT NULL,PRIMARY KEY (pin_history_id, item_type_id));",
            "CREATE TABLE pin_history_products (pin_history_id CHAR(36) NOT NULL,manufacturer_product_id BIGINT UNSIGNED NOT NULL,quantity INTEGER NOT NULL,measure_1 DECIMAL(8, 2) NULL,measure_2 DECIMAL(8, 2) NULL,measure_3 DECIMAL(8, 2) NULL,author_id INTEGER NULL,created_at TIMESTAMP NULL,updated_at TIMESTAMP NULL,deleted_at TIMESTAMP NULL);",
            "CREATE TABLE pin_history_photos (id INTEGER PRIMARY KEY NOT NULL,pin_history_id CHAR(36) NOT NULL,server_id INTEGER NULL,file_path LONGTEXT NULL,file_name VARCHAR(125) NOT NULL,created_at TIMESTAMP NULL,updated_at TIMESTAMP NULL,deleted_at TIMESTAMP NULL);",
            "CREATE TABLE products (id BIGINT PRIMARY KEY NOT NULL,name VARCHAR(125) NOT NULL,status_id BIGINT UNSIGNED DEFAULT '1' NOT NULL,measure_id INTEGER NOT NULL,author_id INTEGER NULL,created_at TIMESTAMP NULL,updated_at TIMESTAMP NULL,deleted_at TIMESTAMP NULL);",
            "CREATE TABLE product_measures (id BIGINT PRIMARY KEY NOT NULL,unit VARCHAR(125) NULL,symbol VARCHAR(5) NULL,input_unit VARCHAR(125) NULL,input_symbol VARCHAR(4) NULL,number_of_inputs TINYINT(1) NULL,deleted_at TIMESTAMP NULL);",
            "CREATE TABLE manufacturers (id BIGINT PRIMARY KEY NOT NULL,name VARCHAR(125) NOT NULL,status_id BIGINT UNSIGNED DEFAULT '1' NOT NULL,author_id INTEGER NOT NULL,created_at TIMESTAMP NULL,updated_at TIMESTAMP NULL,deleted_at TIMESTAMP NULL);",
            "CREATE TABLE manufacturer_product (id BIGINT PRIMARY KEY NOT NULL,product_id BIGINT UNSIGNED NOT NULL,manufacturer_id BIGINT UNSIGNED NOT NULL,author_id INTEGER NOT NULL,created_at TIMESTAMP NULL,updated_at TIMESTAMP NULL,deleted_at TIMESTAMP NULL);",
            "CREATE TABLE manufacturer_prices (id BIGINT PRIMARY KEY NOT NULL,manufacturer_product_id BIGINT UNSIGNED NOT NULL,tier DECIMAL(8, 2) NOT NULL,operative_cost DECIMAL(8, 2) NOT NULL,subcontractor_cost DECIMAL(8, 2) NOT NULL,material_cost DECIMAL(8, 2) NOT NULL,sell_price DECIMAL(8, 2) NOT NULL,start_at DATE NULL,end_at DATE NULL,author_id INTEGER NOT NULL,created_at TIMESTAMP NULL,updated_at TIMESTAMP NULL,deleted_at TIMESTAMP NULL);",
            "CREATE TABLE item_types (id BIGINT PRIMARY KEY NOT NULL,title VARCHAR(125) NOT NULL,description VARCHAR(255) NULL);",
            "CREATE TABLE server_sync (id INTEGER PRIMARY KEY NOT NULL,last_synced_at TIMESTAMP NOT NULL,data TEXT NULL);"
          ];

          const version2Statements: string[] = [
            `ALTER TABLE products ADD COLUMN display_measure VARCHAR(125) NULL;`
          ];

          const version3Statements: string[] = [
            `ALTER TABLE item_types ADD COLUMN deleted_at TIMESTAMP NULL;`,
            "CREATE TABLE manufacturer_product2 (id BIGINT PRIMARY KEY NOT NULL,product_id BIGINT UNSIGNED NOT NULL,manufacturer_id BIGINT UNSIGNED NULL,author_id INTEGER NOT NULL,created_at TIMESTAMP NULL,updated_at TIMESTAMP NULL,deleted_at TIMESTAMP NULL);",
            "INSERT INTO manufacturer_product2 SELECT * FROM manufacturer_product;",
            `DROP TABLE manufacturer_product;`,
            "ALTER TABLE manufacturer_product2 RENAME TO manufacturer_product"
          ];

          const version4Statements: string[] = [
            `ALTER TABLE sites ADD COLUMN notes TEXT NULL;`
          ];

          const version5Statements: string[] = [
            // Create new table with UUID primary key
            `CREATE TABLE pin_history_products_new (
              id CHAR(36) NOT NULL PRIMARY KEY DEFAULT (lower(
                hex(randomblob(4)) || '-' ||
                hex(randomblob(2)) || '-' ||
                '4' || substr(hex(randomblob(2)), 2) || '-' ||
                substr('89ab', (abs(random()) % 4) + 1, 1) ||
                substr(hex(randomblob(2)), 2) || '-' ||
                hex(randomblob(6))
              )),
              pin_history_id CHAR(36) NOT NULL,
              manufacturer_product_id BIGINT UNSIGNED NOT NULL,
              quantity INTEGER NOT NULL,
              measure_1 DECIMAL(8, 2) NULL,
              measure_2 DECIMAL(8, 2) NULL,
              measure_3 DECIMAL(8, 2) NULL,
              author_id INTEGER NULL,
              created_at TIMESTAMP NULL,
              updated_at TIMESTAMP NULL,
              deleted_at TIMESTAMP NULL
            )`,

            // Copy existing data
            `INSERT INTO pin_history_products_new (
              pin_history_id,
              manufacturer_product_id,
              quantity,
              measure_1,
              measure_2,
              measure_3,
              author_id,
              created_at,
              updated_at,
              deleted_at
            )
            SELECT
              pin_history_id,
              manufacturer_product_id,
              quantity,
              measure_1,
              measure_2,
              measure_3,
              author_id,
              created_at,
              updated_at,
              deleted_at
            FROM pin_history_products`,

            // Drop the original table
            `DROP TABLE pin_history_products`,

            // Rename the new table
            `ALTER TABLE pin_history_products_new RENAME TO pin_history_products`
          ];

          await this.sqlite.sqlite.addUpgradeStatement(environment.db.name, [
            {
              toVersion: 1,
              statements: version1Statements
            },
            {
              toVersion: 2,
              statements: version2Statements
            },
            {
              toVersion: 3,
              statements: version3Statements
            },
            {
              toVersion: 4,
              statements: version4Statements
            },
            {
              toVersion: 5,
              statements: version5Statements
            }
          ]
          ).catch((error: any) => {
            this.uiService.errorToast('Error setting up database, please restart your app.');
            console.error('Error setting up database', error);
          }).then(() => {
            console.log("Successfully added upgrade statements");
          });

          return Promise.resolve();
        } catch (error) {
          return Promise.reject(error);
        }
      });
    });
  }
}
