import {HttpClient}            from '@angular/common/http';
import {Injectable, Injector}            from '@angular/core';
import {JwtHelperService}      from '@auth0/angular-jwt';
import * as SentryAngular      from "@sentry/angular";
import {Observable, of}        from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {BaseService}           from './base.service';
import {PermissionsService}    from './permissions.service';
import {StorageService}        from "./storage.service";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends BaseService
{
    public authenticated = false;

    constructor(
        public jwtHelper: JwtHelperService,
        private httpClient: HttpClient,
        private storageService: StorageService,
        private permissionsService: PermissionsService,
        private router: Router,
    )
    {
        super();
    }

    public get accessToken(): string
    {
        return this.storageService.read('accessToken');
    }

    public set accessToken(token: string)
    {
        this.storageService.write('accessToken', token);
    }

    get tenant(): any {
        return this.storageService.read('tenant') ? JSON.parse(this.storageService.read('tenant')) : null;
    }

    set tenant(tenant: any) {
        this.storageService.write('tenant', JSON.stringify(tenant));
    }

    public get user(): any
    {
        try {
            return JSON.parse(this.storageService.read('user'));
        } catch (error) {
            this.deauthenticateUser();
            return null;
        }
    }

    public set user(user: any)
    {
        this.storageService.write('user', JSON.stringify(user));
    }

    public login(email: string | null, password: string | null): Observable<any>
    {
        if (this.authenticated) {
            this.handleError('User is already authenticated.');
        }

        return this.httpClient.post(`${this.apiUrl}/login`, {
            email: email,
            password: password
        }, {
          headers: {
            'X-Client-Ident': `${environment.webEnum}`,
            'X-Skip': 'true'
          }
        }).pipe(
            switchMap(async (response: any) => {
                await this.authenticateUser(response);
                return of(response);
            })
        );
    }

    public signInUsingToken(): Observable<any>
    {
        if (this.accessToken) {
            return this.httpClient.post(`${this.apiUrl}/refresh`, {
                token: this.accessToken,
                tenant_id: this.tenant.id
            }).pipe(
                catchError(() => of(false)),
                switchMap(async (response: any) => {
                    this.authenticateUser(response);
                    return of(true);
                })
            );
        }

        return of(false);
    }

    public logout(): Observable<any>
    {
        this.deauthenticateUser();
        return of(true);
    }

    public check(): Observable<boolean>
    {
        if (!this.accessToken) {
            return of(false);
        }

        if (!this.tokenExpired(this.accessToken)) {
            return of(true);
        }

        if (this.authenticated) {
          return of(true);
        }

        // return this.signInUsingToken();
        return of(false);
    }

    public forgotPassword(email: string)
    {
        if (this.authenticated) {
            this.handleError('User is already authenticated.');
        }

        return this.httpClient.post(`${this.apiUrl}/forgot-password`, {
            email: email,
        }).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    public createPassword(url: string, passwordData: any): Observable<any>
    {
        if (this.authenticated) {
            this.handleError('User is already authenticated.');
        }

        return this.httpClient.post(`${url}`, passwordData).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    public resetPassword(token: string, email: string, password: string, passwordConfirmation: string)
    {
        if (this.authenticated) {
            this.handleError('User is already authenticated.');
        }

        return this.httpClient.post(`${this.apiUrl}/reset-password`, {
            token: token,
            email: email,
            password: password,
            password_confirmation: passwordConfirmation
        }).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    public verifyEmail(code: string, hash: string): Observable<any>
    {
        return this.httpClient.post(`${this.apiUrl}/email/verify`, {
            code: code,
            hash: hash
        }).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    public resendVerificationEmail(email: string): Observable<any>
    {
        if (this.authenticated) {
            this.handleError('User is already authenticated.');
        }

        return this.httpClient.post(`${this.apiUrl}/email/verify/resend`, {email: email}).pipe(
            switchMap((response: any) => {
                return of(response);
            })
        );
    }

    private async authenticateUser(response: any)
    {
        this.authenticated = true;
        if(response.data?.user){
            this.user = response.data.user;

            SentryAngular.setUser({
                id: this.user.id,
                email: this.user.email,
                name: this.user.name
            });

            this.accessToken = response.data.authorisation.access_token;
            this.tenant = response.data.primary_tenant;

            this.permissionsService.roles = response.data.user.roles;
            this.permissionsService.permissions = response.data.user.permissions;
        } else {
            this.deauthenticateUser();
            this.router.navigate(['/login']);
        }
    }

    public deauthenticateUser()
    {
        this.authenticated = false;
        SentryAngular.setUser(null);
        this.storageService.remove('user');
        this.storageService.remove('tenant');
        this.storageService.remove('accessToken');
        this.storageService.remove('roles');
        this.storageService.remove('permissions');

        this.router.navigate(['login']);
    }

    private tokenExpired(token: string) {
        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        return (Math.floor((new Date).getTime() / 1000)) >= expiry;
    }
}
