import { Component, ViewChild } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UiService } from 'src/app/shared/services/ui.service';
import packageInfo from '../../../../../package.json';

@Component({
	selector: 'app-otp-verification',
	templateUrl: './otp-verification.component.html',
    styleUrls: ['./otp-verification.component.scss'],
})
export class OtpVerificationComponent {
    @ViewChild('otpInput') otpInput:any;

    public appVersion: string = packageInfo.version;
    public email: string;
    public hash: string;
    public timeRemaining$: Observable<number>;
    public otpConfig = {
        length: 6,
        allowNumbersOnly: true,
        placeholder: '-',
    };

	constructor(
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private uiService: UiService,
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            this.email = params['email'];
            this.hash = params['hash'];
        });
	}

	public onOtpChange(event: any){
        if(event.length === this.otpConfig.length){
            this.otpInput.otpForm.disable();
            this.authService.verifyEmail(event, this.hash).subscribe({
                next: () => {
                    this.router.navigate(['/login']);
                    this.uiService.toast('Email verified successfully, you can now login.', 'success');
                },
                error: response => {
                    this.uiService.toast(`Invalid Code, ${response.error.message}`, 'error');
                    this.otpInput.otpForm.reset();
                    this.otpInput.otpForm.enable();
            }});
        }
    }

    public onResendOtp(){
        this.authService.resendVerificationEmail(this.email).subscribe({
            next: (response: any) => {
                this.hash = response.hash;
                this.uiService.toast('You have been sent a new verification code in an email.', 'success');
            },
            error: () => this.uiService.toast('There has been a problem resending you an email, please try again', 'error')
        });
        this.otpInput.otpForm.reset();
        this.otpInput.otpForm.enable();

        this.timeRemaining$ = timer(0, 1000).pipe(
            map(n => (30 - n) * 1000),
            takeWhile(n => n >= 0),
        );
    }
}
