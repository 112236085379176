import {Component, Injector, OnInit}                          from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService}                        from "../../../shared/services/auth.service";
import {Router}                             from "@angular/router";
import {UiService}                          from "../../../shared/services/ui.service";
import packageInfo                          from '../../../../../package.json';
import { DatabaseService } from 'src/app/shared/services/database.service';
import { SqliteService } from 'src/app/shared/services/sqlite.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit
{
    public appVersion: string = packageInfo.version;
    public errors: any;
    public submitted: boolean = false;
    public loginForm = new FormGroup({
        email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
        password: new FormControl<string | null>(null, Validators.required),
    });

    public constructor(
        private authService: AuthService,
        private router: Router,
        private uiService: UiService,
        private injector: Injector,
        private sqlite: SqliteService,
    )
    {
    }

    public async ngOnInit(): Promise<void> {
      if(this.sqlite.platform !== 'web'){
        await this.sqlite.checkConnection();
      }
    }

    public submit()
    {
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }

      this.loginForm.disable();

      this.authService.login(this.loginForm.controls.email.value, this.loginForm.controls.password.value).subscribe({
          next: async () => {
              this.router.navigateByUrl('app/sites');
              this.loginForm.enable();
              const dbService = this.injector.get(DatabaseService);
              if(this.sqlite.platform === 'web'){
                await this.sqlite.checkConnection();
              }
              await dbService.createItemTypes();
              await dbService.getProducts();
              await dbService.getProductMeasures();
              await dbService.getManufacturers();
          },
          error: response => {
              this.submitted = false;
              this.errors = response?.error?.errors;
              this.loginForm.enable();

              if(response.status == 403){
                  this.uiService.toast('You have been sent a verification code, Please check your email.');
                  this.router.navigate(['/code-verification'], { queryParams: { hash: response.error.hash, email: this.loginForm.get('email').value } });
              } else {
                this.uiService.errorToast(response?.error?.message);
              }
          }
      });
    }
}
