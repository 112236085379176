import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from "./core/components/login/login.component";
import { ForgotPasswordComponent } from "./core/components/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./core/components/reset-password/reset-password.component";
import { NoAuthGuard } from "./shared/guards/no-auth.guard";
import { AuthGuard } from "./shared/guards/auth.guard";
import { OtpVerificationComponent } from './core/components/otp-verification/otp-verification.component';
import { CommonModule, KeyValuePipe } from '@angular/common';
import { CreatePasswordComponent } from './core/components/create-password/create-password.component';

const routes: Routes = [
	{
		path: 'login',
		canActivate: [NoAuthGuard],
		canActivateChild: [NoAuthGuard],
		component: LoginComponent,
	},
	{
		path: 'forgot-password',
		canActivate: [NoAuthGuard],
		canActivateChild: [NoAuthGuard],
		component: ForgotPasswordComponent,
	},
	{
		path: 'reset-password/:token',
		canActivate: [NoAuthGuard],
		canActivateChild: [NoAuthGuard],
		component: ResetPasswordComponent,
	},
  {
    path: 'create-password',
    canActivate: [NoAuthGuard],
		canActivateChild: [NoAuthGuard],
    component: CreatePasswordComponent
  },
	{
		path: 'code-verification',
		canActivate: [NoAuthGuard],
		canActivateChild: [NoAuthGuard],
		component: OtpVerificationComponent,
    providers: [KeyValuePipe]
	},
	{
		path: 'app',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		loadChildren: () => import('./core/core.module').then(m => m.CoreModule),
	},
	{
		path: '',
		redirectTo: 'app',
		pathMatch: 'full'
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
