import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
	selector: 'app-create-password',
	templateUrl: './create-password.component.html',
})
export class CreatePasswordComponent implements OnInit {
    public passwordForm: UntypedFormGroup;
    public error: any;
    public submitted = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private uiService: UiService,
    ) {
        if (this.authService.authenticated) {
            this.router.navigate(['/app']).then(r => r);
        }
    }

    get f() {
        return this.passwordForm.controls;
    }

    public ngOnInit(): void {
        this.passwordForm = this.fb.group({
            password: [null, Validators.required],
            password_confirmation: [null, Validators.required],
        });
    }

    public onSubmit(): void {
        this.submitted = true;

        if (this.passwordForm.invalid) {
            return;
        }

        this.passwordForm.disable();

        console.log('Param url', this.activatedRoute.snapshot.queryParamMap.get('url'));

        this.authService.createPassword(this.activatedRoute.snapshot.queryParamMap.get('url'), this.passwordForm.value).subscribe({
            next: () => {
                this.uiService.toast('Password successfully created. You can now login.', 'success');
                this.router.navigateByUrl('/login').then(r => r);
            },
            error: (response: HttpErrorResponse) => {
                this.passwordForm.enable();
                this.uiService.errorToast(response?.error?.message);
            }
        });
    }
}
