<ion-content class="ion-padding">
  <div class="login-logo" routerLink="/"></div>

      <ion-item lines="none" class="ion-margin-vertical">
          <ion-label class="ion-text-wrap">
              <h1 class="header">Verification Code</h1>
              <p>Please enter the code you have been sent in your email.</p>
          </ion-label>
      </ion-item>

      <ion-item lines="none" class="ion-margin-vertical">
          <ng-otp-input #otpInput (onInputChange)="onOtpChange($event)" [config]="otpConfig"></ng-otp-input>
      </ion-item>
      <ion-item lines="none" class="ion-margin-vertical">
            <div class="col-12 mt-3">
                <span *ngIf="(timeRemaining$ | async) > 0; else requestEmail" class="text-muted a-link-margin">
                    Didn't receive your email? Request another in <br />
                    <a class="text-primary a-link-margin">{{ timeRemaining$ | async | date:'ss' }} seconds</a>
                </span>
                <ng-template #requestEmail>
                    <a class="text-primary a-link-margin" (click)="onResendOtp()">Didn't receive the code?</a>
                </ng-template>
            </div>
    </ion-item>
</ion-content>
